.app {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.extra-text a {
  color: #047857;
  text-decoration: underline;
  font-weight: 700;
}

.lang-button {
  background-color: #3498db;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.lang-button:hover {
  background-color: #2980b9;
}

.lang-switcher {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin: 20px;
}

.slick-slider {
  width: 90vw;
  box-sizing: border-box;
}

.slick-slider img {
  object-fit: contain; 
}

.slick-prev:before,
.slick-next:before {
  color: #166534 !important;
}

@media only screen and (min-width: 769px) {
  .carousel-image {
    max-height: 70vh;
    height: auto;
  }
  .slick-slider {
    width: 50vw;
    box-sizing: border-box;
    max-height: 100vh;
  }
}

.app a {
  text-decoration: underline;
  font-weight: 700;
  color: #047857;
}
